<script>
export default {
  name: "KBImgPreview",
};
</script>

<template>
  <b-img src="./assets/kbTemplate.png"></b-img>
</template>

<style scoped lang="scss"></style>
