<script>
export default {
  name: "TemplateStepper",
  props: {
    steps: {
      default: [],
    },
    current: {
      type: Number,
      default: 1
    }
  },

  methods: {
    setCurrent(value) {
      this.current = value
      this.$emit('onChange', value)
    },

    getClases(step) {
      if (step.id > 1 && step.enabled === false) {
        return 'disabled'
      } else if (this.current === step.id) {
        return 'visited'
      } else return ''
    }
  },

}
</script>

<template>
  <div>
    <ol class="stepBar">
      <li v-for="(s, index) in steps" :key="index" :class="getClases(s)">
        <span @click="setCurrent(s.id)">{{ s.id }}</span><br> {{ s.name }}
      </li>
    </ol>
  </div>
</template>

<style scoped lang="scss">
.stepBar {
  padding-inline: 0;
  display: flex;
  max-width: 700px;
  position: relative;
  margin: 20px auto;
  text-align: center;
}

.stepBar li {
  font-size: 12px;
  list-style: none;
  position: relative;
  width: 33.333%;
}

.stepBar li:after {
  background: #eaeaea;
  content: "";
  width: calc(100% - 25px);
  height: 2px;
  position: absolute;
  left: calc(-50% + 12px);
  top: 10px;
}

.stepBar li:first-child:after {
  display: none;
}

.stepBar li span {
  cursor: pointer;
  background: none;
  color: #1b2337;;
  border: 1px solid #efb810;
  display: inline-block;
  height: 25px;
  margin-bottom: 5px;
  line-height: 25px;
  width: 25px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.stepBar .visited:after {
  /*   background: #4D648D; */
}

.stepBar .visited span {
  background: #efb810;
  color: #fff;
  transition: 0.2s ease-in;
}

.visited {
  font-weight: 700
}

.disabled span {
  cursor: auto !important;
  pointer-events: none;
  color: #575757 !important;
  background: #eeeeee !important;
  border: 1px solid #c2c2c2 !important;
}

</style>
