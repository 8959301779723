<script>
import { BTr } from "bootstrap-vue";
// import apostalaCharger from "@/views/components/whitelabel-templates/Apostala/ApostalaCharger.vue";
import apostalaImgPreview from "@/views/components/whitelabel-templates/Apostala/ApostalaImgPreview.vue";
// import kingboxplusCharger from "@/views/components/whitelabel-templates/KB/KBCharger.vue";
import kingboxplusImgPreview from "@/views/components/whitelabel-templates/KB/KBImgPreview.vue";
// import appCharger from "@/views/components/whitelabel-templates/App/AppCharger.vue";
import appImgPreview from "@/views/components/whitelabel-templates/App/AppImgPreview.vue";
import store from "@/store";
import whitelabelTemplatesModule from "../store/templatesStoreModule";
import { mapActions, mapState } from "vuex";
import godmoneyImgPreview from "@/views/components/whitelabel-templates/GodMoney/GodMoneyImgPreview.vue";

const MODULE_APP_STORE_NAME = "app-whitelabel-templates";

export default {
  name: "TemplateSelector",
  components: { BTr, apostalaImgPreview, kingboxplusImgPreview, appImgPreview, godmoneyImgPreview},

  data() {
    return {
      MODULE_APP_STORE_NAME: "app-whitelabel-templates",
      selected: "",
      errorFetchingDefaultTemplates: false,
      defaultTemplates: [],
    };
  },

  computed: {
    ...mapState("app-whitelabel-templates", ["loadingFetchDefaults"]),

    getWhitelabelSelect() {
      return store.state.whitelabelCurrencyNabvar.whitelabel;
    },
  },

  watch: {
    getWhitelabelSelect: {
      handler(newWhitelabel,oldWhitelabel) {
        if (newWhitelabel._id !== oldWhitelabel._id) 
          this.loadTemplates();
      },
    },
  },

  methods: {
    ...mapActions(MODULE_APP_STORE_NAME, ["fetchDefaultTemplates"]),

    setSelected(type) {
      this.selected = type;
      this.$emit("onSelected", type);
    },

    getTemplateByType(type) {
      return this.defaultTemplates.find((t) => t.slug === "type");
    },

    async loadTemplates() {
      try {
        const queryParams = {
          whitelabelId:
            this.$store.state.whitelabelCurrencyNabvar.whitelabel._id,
        };
        const response = await this.fetchDefaultTemplates(queryParams);
        this.defaultTemplates = response.data;
      } catch (e) {
        this.errorFetchingDefaultTemplates = true;
        console.error("Error fetching default templates:", e);
      }
    },
  },

  async mounted() {
    await this.loadTemplates();
  },

  beforeCreate() {
    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);
  },

  beforeDestroy() {
    if (store.hasModule(this.MODULE_APP_STORE_NAME))
      store.unregisterModule(this.MODULE_APP_STORE_NAME);
  },
};
</script>

<template>
  <div class="container mx-auto py-4">
    <div
      v-if="loadingFetchDefaults"
      class="d-flex justify-content-center align-items-center"
    >
      <b-spinner></b-spinner>
    </div>

    <div v-else-if="errorFetchingDefaultTemplates">{{ $t('error_loading_templates') }}</div>

    <div v-else>
      <div
        v-if="!defaultTemplates.length"
        class="d-flex justify-content-center"
      >
        {{ $t('no_templates') }}
      </div>
      <div v-else class="row justify-content-center" style="gap: 40px">
        <div
          v-for="(template, index) in defaultTemplates"
          :key="index"
          class="template-card"
          :class="{ selected: selected === template.type }"
        >
          <div class="card">
<!--            <component-->
<!--              :is="`${template.type}-charger`"-->
<!--              size="monitor"-->
<!--              class="image"-->
<!--              custom-width="100%"-->
<!--              custom-height="800%"-->
<!--              style="-->
<!--                height: 310px;-->
<!--                width: 2040px;-->
<!--                transform: scale(0.15);-->
<!--                transform-origin: 0 0;-->
<!--              "-->
<!--              :template-data="template.templateData"-->
<!--            >-->
<!--            </component>-->

            <component
                :is="`${template.type}-img-preview`"
                class="image"
                style="
                height: 100%;
                width: 100%;
              "
            >
            </component>

            <div class="middle">
              <b-button @click="setSelected(template.type)" variant="secondary">
                {{ $t('labels.select') }}
              </b-button>
            </div>
          </div>
          <p class="d-inline">{{ template.name }}</p>
          <span> ({{ template.type }})</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.template-card {
  .card {
    border-radius: 8px;
    overflow: hidden;
    height: 370px;
    width: 310px;

    .image {
      //height: 310px;
      //width: 2040px;
      //transform: scale(0.15);
      //transform-origin: 0 0;
      opacity: 1;
      transition: 0.5s ease;
      backface-visibility: hidden;
    }

    .middle {
      transition: 0.5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
  }

  &.selected .card {
    border: 2px solid #efb810;
    padding: 5px;
    transition: 0.1s ease-in;
    z-index: 1;
  }

  &:hover {
    .image {
      opacity: 0.3;
    }
    .middle {
      opacity: 1;
    }
  }
}
</style>
